// App.js
import React, { useState, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import './App.scss';
import { theme, backgroundTheme } from './theme';
import profile from './profile.jpg';
import { useOnClickOutside } from './hooks';
import { Burger, Menu } from './components';

function App() {
  const [open, setOpen] = useState(false);
  const node = useRef(); 
  useOnClickOutside(node, () => setOpen(false));
  return (
    <ThemeProvider theme={theme} backgroundTheme={backgroundTheme}>
      <>
        <GlobalStyles />
        <div style={{width: "100vw", height: "100vh"}}>
          <section className="Showcase">
            <h1>Hello World, This is Alex</h1>
            <img className="Profile-image" src={profile} alt="profile">
            </img>
            <h1>Automated</h1>
          </section>
        </div>
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} />
        </div>
      </>
    </ThemeProvider>
  );
}
export default App;

/* import React from 'react';
import profile from './profile.jpg'
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div
          className="App-link"
        >
          Alex VT
        </div>
      </header>
      <main>
        <div style={{width: "100%", height: "100vh"}}>
        <section className="Showcase">
          <img className="Profile-image" src={profile} alt="profile">
          </img>
        </section>
        </div>
      </main>
    </div>
  );
}

export default App; */
