// theme.js
export const theme = {
    primaryDark: '#0D0C1D',
    primaryLight: '#EFFFFA',
    primaryHover: '#343078',
    mobile: '576px',
    samuraiBackground: "samurai.jpg"
  }

export const backgroundTheme = {
    samurai: "samurai.jpg"
}